<nav
  *ngIf="showNav"
  class="navbar sticky-top navbar-expand-lg navbar-light bg-light"
>
  <button
    *ngIf="show_sidebar_btn"
    class="navbar-toggler"
    type="button"
    (click)="showSidebar()"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" href="#">Holidays Island</a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <div class="container">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link" routerLink="/"
            >Home <span class="sr-only">(current)</span></a
          >
        </li>
        <li class="nav-item active">
          <a class="nav-link" routerLink="/dashboard"
            >Dashboard <span class="sr-only">(current)</span></a
          >
        </li>
      </ul>
    </div>

    <a class="nav-link active" (click)="logout()" aria-current="page">Logout</a>
  </div>
</nav>

<router-outlet (activate)="onOutletLoaded($event)"></router-outlet>
